import type React from 'react'
import { useEffect, useRef } from 'react'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { readCouponDetailsFromUrl } from 'mobx/CouponFlow/utils'
import { CONSTANTS } from 'utils/constants'
// import { initOrUpdateSession } from 'utils/utils'

const UrlActionHandler: React.FC = () => {
	const { path, query, hash, removeHash } = useCurrentUrlInfo()
	// const [ref, setRef] = useState<string>('')

	const handledHashes = useRef<Set<string>>(new Set())

	const { couponFlowStore } = useStores()

	const handleExternalCouponFromUrl = async () => {
		if (couponFlowStore.couponToApply || !hash || (hash && handledHashes.current.has(hash))) {
			return
		}

		const couponDetails = readCouponDetailsFromUrl({ query, hash })

		if (couponDetails) {
			handledHashes.current.add(hash)
			removeHash()
			console.log('couponFlowStore.start', couponDetails)
			await couponFlowStore.start({
				code: couponDetails.code,
				orderTypeToForce: couponDetails.orderTypeToForce,
				openModal: true,
			})
		}
	}

	// todo Jacob, add ref listener here
	// const handleRefFromUrl = () => {
	// 	console.log('handleRefFromUrl', query)
	// 	const queryRef = query.ref
	// 	if (queryRef && typeof queryRef === 'string') {
	// 		// todo Jacob, add ref logic here
	// 		console.log('queryRef', queryRef)
	// 		if (ref !== queryRef) {
	// 			console.log('set new ref')
	// 			setRef(queryRef)
	// 			// initOrUpdateSession({
	// 			// 	refObject: { ref: queryRef },
	// 			// })
	// 		}
	// 	}
	// }

	useEffect(() => {
		const handleRouteChange = async () => {
			if (hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.ITEM) || hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.DISCOUNT)) {
				await handleExternalCouponFromUrl()
			}
			// handleRefFromUrl()
		}

		handleRouteChange()
	}, [path, query, hash])

	return null // This component does not render anything
}

export default observer(UrlActionHandler)

// @ts-nocheck
import Application from 'mobx/Application'
import Infra from 'mobx/Infra'

import { isNodeJsEnv } from '../../utils'
import { CONSTANTS } from '../../constants'

const ga4Promise = isNodeJsEnv ? Promise.resolve() : import(/* webpackMode: "eager" */ './ga4')
const ga4KFCGlobalPromise = isNodeJsEnv ? Promise.resolve() : import(/* webpackMode: "eager" */ './ga4KFCGlobal')

/**
 * Promise caches the first resolved value so we await this promise in as many
 * places as we need the resolved value will be always the same gtm module instance
 */
const tagManagerPromise = isNodeJsEnv
	? new Promise((resolve) => {
			console.info('Running inside static page generator so not importing the TagManager plugin')
			resolve(null)
	  })
	: import(/* webpackMode: "eager" */ 'react-gtm-module')

const googleTagConsumers = []

const sendEvent = async (event) => {
	const tagManager = await tagManagerPromise
	if (!tagManager) {
		return
	}

	const resetDataLayer = !Infra?.appParams?.features?.removeNullEvents
	if (resetDataLayer) {
		// we are advised to 'reset' the dataLayer but this generates a 'Message' entry in the https://tagassistant.google.com/ page
		tagManager.dataLayer({
			dataLayer: {
				ecommerce: null,
				category: null,
				action: null,
				label: null,
				event: null,
				variations: null,
				postLocalisation: null,
				storeID: null,
				disposition: null,
				// userID: null,
				loginMethod: null,
				// firstName: null,
				// lastName: null,
				// emailAddress: null,
				// phoneNumber: null,
				popupTitle: null,
				errorMessage: null,
				pageTitle: null,
				pageURL: null,
				item: null,
			},
		}) // Clear the previous ecommerce object
	}

	// send the real event
	if (Object.prototype.hasOwnProperty.call(event, 'dataLayer')) {
		tagManager.dataLayer({
			...event,
			dataLayer: {
				...event.dataLayer,
				session_channel: Application.isMobileApp ? CONSTANTS.CHANNEL_TYPE.APP.toLowerCase() : CONSTANTS.CHANNEL_TYPE.WEB.toLowerCase(),
			},
		})
	} else {
		tagManager.dataLayer(event)
	}
}

export async function init(params) {
	const TagManager = await tagManagerPromise
	if (!TagManager) {
		return
	}

	let gtmIds = new Set<string>()

	if (params?.analytics?.googleTagManager?.ids?.length && !params?.analytics?.googleTagManager?.ids.some((id) => id.indexOf('XXX') > -1)) {
		gtmIds = new Set(params?.analytics?.googleTagManager?.ids)
	}

	if (params?.analytics?.googleTagManager?.id?.indexOf('XXX') === -1) {
		gtmIds.add(params?.analytics?.googleTagManager?.id)
	}

	if (gtmIds.size > 0) {
		gtmIds.forEach((id) => {
			console.log(`init. Google Tag Manager with id: ${id}`)
			const googleTagManagerArgs = {
				gtmId: id,
			}
			TagManager.initialize(googleTagManagerArgs)
		})

		if (params.analytics.googleTagManager.consumers) {
			params.analytics.googleTagManager.consumers.map(async (_consumerName) => {
				switch (_consumerName) {
					case 'GA4': {
						const ga4 = await ga4Promise
						ga4.init(TagManager, sendEvent)
						googleTagConsumers.push(ga4)
						break
					}
					case 'GA4_KFC_GLOBAL': {
						const ga4KFCGlobal = await ga4KFCGlobalPromise
						ga4KFCGlobal.init(TagManager, sendEvent)
						googleTagConsumers.push(ga4KFCGlobal)
						break
					}
					default: {
						console.warn(`unknown GTM event format: ${_consumerName}`)
					}
				}
			})
		} else {
			console.warn(`Missing 'analytics.googleTagManager.consumers' !`)
		}
	} else {
		console.info(`Missing Google Tag Manager Id or it's in the wrong format!`)
	}
}

export function sendEnhancedEcommerceEvent(eventType, ...args) {
	googleTagConsumers.map((_consumer) => {
		const mappedEvent = _consumer.triggerSendEvent(eventType, ...args)
		// sendEvent(mappedEvent)
	})
}

/**
 *
 * @param event = {
 * 		category: '',
 *		action: '',
 *		label: '',
 * }
 *
 * event is hard-coded to 'custom-event' as per advice from our (GTM expert)
 *
 */
export function sendCustomEvent(data) {
	googleTagConsumers.map((_consumer) => {
		const mappedEvent = _consumer.triggerSendEvent(CONSTANTS.ANALYTICS.OTHER_EVENTS.custom, data)
		// sendEvent(mappedEvent)
	})
}

export function sendPageViewEvent(data) {
	googleTagConsumers.map((_consumer) => {
		const mappedEvent = _consumer.triggerSendEvent(CONSTANTS.ANALYTICS.OTHER_EVENTS.pageView, data)
		// sendEvent(mappedEvent)
	})
}

// @ts-nocheck
import React from 'react'
import { getTranslatedTextByKey } from 'utils/utils'
import styled from 'styled-components/macro'
import TypographyPro from 'themes/TypographyPro'
import Nutrient from './Nutrient'

const StyledContent = styled(TypographyPro)`
	display: ${(props) => (props.shown === 'true' ? 'block' : 'none')};
	margin-top: 8px;
	border-style: dashed;
	border-width: 1px;
	padding: 5px;
`

const StyledTitle = styled(TypographyPro)`
	display: inline;
`

const StyledRow = styled.span`
	display: block;
`

const StyledNutrientsContainer = styled.div`
	margin-left: 10px;
`

const StyledBoldTitle = ({ children }) => <StyledTitle variant="BodySmallHighlighted">{children} :</StyledTitle>

const nutritionalTexts = {
  additives: getTranslatedTextByKey('eCommerce.menuItem.nutritional.additives', 'Additives'),
  allergens: getTranslatedTextByKey('eCommerce.menuItem.nutritional.allergens', 'Allergens'),
  ingredients: getTranslatedTextByKey('eCommerce.menuItem.nutritional.ingredients', 'Ingredients'),
  nutrients: getTranslatedTextByKey('eCommerce.menuItem.nutritional.nutrients', 'Nutrients'),
  storageInstructions: getTranslatedTextByKey('eCommerce.menuItem.nutritional.storageInstructions', 'Storage instructions'),
  manufacturer: getTranslatedTextByKey('eCommerce.menuItem.nutritional.manufacturer', 'Manufacturer'),
}

const NutritionalInformationContent = ({ data, shown }) => {
  return (
    <StyledContent shown={shown} variant="BodySmall" component="div">
      <StyledRow>
        <StyledBoldTitle>{nutritionalTexts.additives}</StyledBoldTitle> {data?.additives?.map((a) => a.name)?.join(', ')}
      </StyledRow>
      <StyledRow>
        <StyledBoldTitle>{nutritionalTexts.allergens}</StyledBoldTitle> {data?.allergens?.map((a) => a.name)?.join(', ')}
      </StyledRow>
      <StyledRow>
        <StyledBoldTitle>{nutritionalTexts.ingredients}</StyledBoldTitle> {data?.ingredients?.map((a) => a.name)?.join(', ')}
      </StyledRow>
      <StyledRow>
        <StyledBoldTitle>{nutritionalTexts.nutrients}</StyledBoldTitle>
        <StyledNutrientsContainer>
          {data?.nutrients
            .filter((n) => n.value !== undefined && n.value !== '')
            .map((nutrient) => (
              <Nutrient key={nutrient.name} data={nutrient} />
            ))}
        </StyledNutrientsContainer>
      </StyledRow>
      <StyledRow>
        <StyledBoldTitle>{nutritionalTexts.storageInstructions}</StyledBoldTitle> {data?.manufacturerDetails.storageInstructions}
      </StyledRow>
      <StyledRow>
        <StyledBoldTitle>{nutritionalTexts.manufacturer}</StyledBoldTitle> {data?.manufacturerDetails.brand} (
        {data?.manufacturerDetails.address})
      </StyledRow>
    </StyledContent>
  )
}

export default NutritionalInformationContent

import { getDomainByEnv, sendRequest } from 'utils/utils'

export const webFlowAddress = {
	getBranchesList: ({
		formatted,
		lat,
		lng,
		chainId,
		lang,
		orderType,
		showLoader = true,
	}: {
		formatted: any
		lat: any
		lng: any
		chainId: any
		lang: any
		orderType: any
		showLoader: any
	}) =>
		sendRequest(
			showLoader,
			`${getDomainByEnv()}webFlowAddress`,
			'post',
			{
				chainId,
				type: 'getBranchesList',
				addr: { formatted, lat, lng },
				cust: 'openRest',
				lang,
				orderType,
				noFilter: true,
			},
			{ 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
		),
}

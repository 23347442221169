import type { SnackBarMessageType } from 'components/common/coupons/types'
import type { THEME_STRATEGY } from 'constants/theme'
import type { CountryCode } from 'libphonenumber-js'
import type React from 'react'
import type { FeatureFlagEnum } from 'types/constants.types'
import type { Page } from 'utils/constants'
import type { Brand } from 'utils/theme/GlobalStyle'

/**
 * Infra interface should include all the functions we need to use
 */
export interface InfraProps {
	showSnackbar: (props: {
		key?: string
		snackId?: string
		message: SnackBarMessageType
		status: string
		isAttachedToElement: boolean
		duration?: number
	}) => void
	setNotification: () => {
		open: boolean
		title: string | React.ReactElement
		message: string | React.ReactElement
		cancelText: string
		okText: string
		closeAction: true
		okAction: () => void
		cancelAction: () => void
	}
	closeNotification: () => void
	appParams: AppParams
	hasFeatureFlag: (flag: FeatureFlagEnum) => boolean
	setLoading: (loading: boolean) => void
	loading: boolean
	eCommerceFooter: {
		apps?: {
			ios?: {
				link: string
			}
			android?: {
				link: string
			}
		}
	}
}

export interface SnackBar {
	snackObj: {
		id: string
		isOpen: boolean
		refEl: React.Ref<HTMLElement>
	}
	message: string
	status: string
	positionAndSize: DOMRect
	isOpen: boolean
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Guard {
	export enum Context {
		wallet = 'wallet',
		account = 'account',
	}

	export enum Type {
		page = 'pages',
		component = 'components',
		event = 'events',
	}

	export enum Name {
		account = 'account',
		applyCoupon = 'applyCoupon',
	}
}

export interface AppParams {
	title: string
	name: string
	c: string
	pc: string
	theme: THEME_STRATEGY
	brand: Brand
	logo: string
	wru: string
	wruec: string
	storefrontUrl: string
	favicon: string
	cust: string
	l: 'en' | 'iw' | 'ar' | 'ru' | 'pt' | 'es' | 'fr' | 'pap' | 'de' | 'fi'
	currency: string
	useProductionMenu: boolean
	useProductionTheme: boolean
	environment: 'PROD' | 'STAGING' | 'DEV' | 'LOCAL' // Injected from the server
	ref?: string
	locale: {
		region: CountryCode
	}
	pageText: Record<string, { title: Record<string, string>; description: Record<string, string> }>
	assets?: {
		homepageBackgroundDesktop: string
		homepageBackgroundMobile: string
	}
	features: Record<string, unknown>
	eCommerce?: boolean
	headlessBrowser: boolean
	mobileApp?: {
		navigationBarData: any
		bundleId?: string
		_deepLinks?: {
			[key: string]: string
		}
	}
	guard?: Record<
		Guard.Context,
		Record<
			Guard.Type,
			[
				{
					name: Guard.Name | Page
					roles: [
						{
							action: 'loginProtected'
							type: 'authenticated'
						}
					]
				}
			]
		>
	>
}

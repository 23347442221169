// @ts-nocheck
import React, { useState } from 'react'
import { getTranslatedTextByKey } from 'utils/utils'
import styled from 'styled-components/macro'
import TypographyPro from 'themes/TypographyPro'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import IconComponent from 'themes/IconComponent'
import NutritionalInformationContent from './NutritionalInformationContent'

const DownArrowIcon = (props) => <IconComponent asset="/icons/dropDownArrow.svg" {...props} />
const TopArrowIcon = (props) => <IconComponent asset="/icons/topArrow.svg" {...props} />

const StyledDownArrowIcon = styled(DownArrowIcon)`
	width: 12px;
	height: 12px;

	path {
		stroke: var(--primary);
	}
`

const StyledUpArrowIcon = styled(TopArrowIcon)`
	width: 12px;
	height: 12px;

	path {
		stroke: var(--primary);
	}
`

const StyledLinkContainer = styled.div`
	display: flex;
	align-items: end;
	gap: 10px;
	padding-top: 5px;
`

const StyledOpener = styled(TypographyPro)`
	color: var(--primary);
`

const nutritionalTexts = {
  hide: getTranslatedTextByKey('eCommerce.menuItem.nutritional.hide', 'Hide nutritional & additional information'),
  show: getTranslatedTextByKey('eCommerce.menuItem.nutritional.show', 'Show nutritional & additional information'),
}

const NutritionalInformation = inject('ItemAdditions')(
  observer(({ id, ItemAdditions }) => {
    const [state, setState] = useState({
      shown: false,
      data: null,
    })

    const onClick = async () => {
      const data = state.data || (await ItemAdditions.getNutrientsInformation(id))
      setState({ shown: !state.shown, data: data })
    }

    return (
      <>
        <StyledLinkContainer onClick={onClick}>
          <StyledOpener variant="BodySmallClickable">{state.shown ? nutritionalTexts.hide : nutritionalTexts.show}</StyledOpener>
          {state.shown && <StyledUpArrowIcon />}
          {!state.shown && <StyledDownArrowIcon />}
        </StyledLinkContainer>
        <NutritionalInformationContent data={state.data} shown={state.shown.toString()} />
      </>
    )
  })
)

NutritionalInformation.propTypes = {
  id: PropTypes.string.isRequired,
}

export default NutritionalInformation

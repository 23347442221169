// @ts-nocheck
import { observable, action, computed } from 'mobx'

import { CONSTANTS } from 'utils/constants'
import { getCodeFromUrlHash } from 'utils/utils'
import storage from 'utils/storage'

import queryString from 'query-string'
import filterItemsAvailability from 'utils/filterItemsAvailability'
import HomeDependencies from './HomeDependencies'
import type { CouponToApply } from 'mobx/CouponFlow/types'
import { OrderType, OfferType } from 'types/Coupons'

class Home {
	dependencies = {}

	/**
	 * Content for the DialogBox
	 *
	 * @type {{}}
	 */
	@observable locale = {}

	@observable logo: { conTxt?: { pickupText: string } } = {}

	@observable branchesList: { error: string | null; msg?: { deliveryStores: string; disablePickup: boolean } } = { error: null }

	@observable featuredItems = {}

	@observable backdropEnabled = false

	@observable clickedFeaturedItem = null

	@observable isHomepage = false

	@observable stores = []

	@observable isUsingMarkerLocation = false

	@observable addressTitle = ''

	@observable currentLocation = {
		address: '',
		lat: 0,
		lng: 0,
	}

	@observable dealType = CONSTANTS.DEALS_TYPES.ITEM

	@computed get closedMessage(): string {
		return this.branchesList.error ? this.branchesList.msg : ''
	}

	@action areAllStoresClosed = (): boolean => this.branchesList.error

	constructor(dependencies) {
		this.dependencies = dependencies
	}

	@action setStores = (stores) => {
		this.stores = stores
	}

	@action setMarkerLocationOption = (option) => {
		this.isUsingMarkerLocation = option
	}

	@action setAddressTitle = (address) => {
		this.addressTitle = address
	}

	@action setCurrentLocation = (location) => {
		this.currentLocation = location
	}

	@action setLocale = (locale) => {
		this.locale = locale
	}

	@action setLogo = (logo) => {
		this.logo = logo
	}

	@action setBranchesList = (data) => {
		this.branchesList = data
	}

	@action filterAndSetFeaturedItems = (featuredItemsServerAnswer) => {
		this.featuredItems = {
			...featuredItemsServerAnswer,
			msg: {
				...(featuredItemsServerAnswer.msg ?? {}),
				items: {
					delivery: filterItemsAvailability(featuredItemsServerAnswer.msg?.items?.delivery ?? []),
					peakup: filterItemsAvailability(featuredItemsServerAnswer.msg?.items?.peakup ?? []),
				},
			},
		}
	}

	@action setBackdropEnabled = (data) => {
		this.dependencies.setBackdropBody(data)
		this.backdropEnabled = data
	}

	@action setClickedFeaturedItem = (data) => {
		this.clickedFeaturedItem = data
	}

	@action setIsHomepage = (isHomePage) => {
		this.isHomepage = isHomePage
	}

	@action setDealType = (dealType) => {
		this.dealType = dealType
	}

	handleItemSelectedMode = (couponToApply: CouponToApply, User) => {
		if (couponToApply.orderTypeToForce) {
			User.setOrderType(couponToApply.orderTypeToForce)
		} else {
			// removing in-store from order types
			// if only 1 order type is left, set it as the default order type
			const filteredOrderTypes = couponToApply.coupon.orderTypes.filter((orderType) => orderType !== OrderType.IN_STORE)
			if (filteredOrderTypes.length === 1) {
				User.setOrderType(filteredOrderTypes[0])
			}
		}
		if (couponToApply.coupon.offerType === OfferType.ITEM) {
			this.openStaticMenuItemInAddressBox(couponToApply)
		} else {
			this.showSelectedStaticDeal(couponToApply, User)
		}
	}

	showSelectedStaticDeal = (couponToApply: CouponToApply) => {
		const {
			coupon: { title, img, code },
		} = couponToApply

		this.showSelectedStaticDealFromParams(code, title, img)
	}

	showSelectedStaticDealFromParams = (couponCode, title, img) => {
		if (!couponCode) {
			return
		}

		const deal = { title, media: { img } }

		this.setDealType(CONSTANTS.DEALS_TYPES.COUPON)
		this.setClickedFeaturedItem(deal)
		this.setBackdropEnabled(true)
	}

	openStaticMenuItemInAddressBox = (couponToApply: CouponToApply) => {
		const { title, img, price } = couponToApply.coupon
		const item = { title, media: { img }, price }

		this.setDealType(CONSTANTS.DEALS_TYPES.ITEM)
		this.setClickedFeaturedItem(item)
		this.setBackdropEnabled(true)
	}

	static isStaticMenuItemAutoLoad = () => {
		const { isStaticMenuItem } = queryString.parse(window.location.search)
		return isStaticMenuItem && !!decodeURI(getCodeFromUrlHash(window.location.hash))
	}

	updatePastOrder = (removeAllItems) => {
		// we don't remove the user's cart from local storage since it's used in the Confirmation Page
		// we don't automatically remove it in the Home Page since the user may have switched stores and we convert their cart to the new store's menu if possible
		const orderCompleted = localStorage.getItem('orderCompleted')
		if (orderCompleted) {
			removeAllItems()
			storage.clearStorage()
		}

		// this is blocking my hash from deals page (Ben)
		// on page load remove any hash added from a user who selected a featured item
		// if (!_isMenuAutoLoad && !_isStaticMenuItemAutoLoad) {
		// 	history.replaceState(null, null, ' ')
		// }

		// we no longer clear the storage since storage ONLY contains the Cart which we now want to keep between stores if possible
		// storage.clearStorage()
	}

	@action getBranchesList = (queryParams, config) => this.dependencies.getBranchesListRequest(queryParams, config)

	@action getBrowseCategories = (...args) => this.dependencies.getBrowseCategoriesRequest(...args)
}

export default new Home(HomeDependencies)

import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useCurrentUrlInfo = () => {
	const router = useRouter()
	const { pathname, query } = router

	const [hash, setHash] = useState<string | null>(null)

	const removeHash = () => {
		if (router.asPath.includes('#')) {
			const [pathWithoutHash] = router.asPath.split('#')

			// Push a new state to the history stack without the hash
			window.history.replaceState({}, document.title, pathWithoutHash)

			// Update the router state
			router.push(pathWithoutHash, undefined, { shallow: true })
		}
	}

	const updateHash = () => {
		if (router.asPath.includes('#')) {
			const [, newHash] = router.asPath.split('#')
			if (newHash !== hash) {
				setHash(`#${newHash}`)
			}
		} else {
			setHash(null)
		}
	}

	useEffect(() => {
		updateHash()
	}, [router, pathname, query])

	return {
		path: pathname,
		query,
		hash,
		removeHash,
	}
}

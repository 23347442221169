import { staticImplements } from 'utils/types'
import type { Availability, CheckCouponOn, CouponFlowDependencies, CouponToApply, StoreStatus } from './types'
import type { Coupon, Channel } from 'types/Coupons'

import AddressManager from 'mobx/AddressManager'
import Home from 'mobx/Home'
import User from 'mobx/User'
import Infra from 'mobx/Infra'
import Account from 'mobx/Account'
import type { NextRouter } from 'next/router'
import type CouponsStore from 'mobx/Coupons'
import { sendRequest } from 'utils/utils'
import { isAvailableNow } from '@tictuk/item-availability'
import { isCouponForChannel } from './utils'
import CouponError, { ErrorCode } from 'mobx/Coupons/errors'

@staticImplements<CouponFlowDependencies>()
export default class CouponFlowRepository {
	static router: NextRouter | null = null

	static couponsStore: CouponsStore | null = null

	static setNotification: Function | null = null

	static closeNotification: Function | null = null

	static areAllStoresClosed: Function | null = null

	static getTranslatedTextByKey: (path: string, fallback?: string) => string

	static setRouter(router: NextRouter): void {
		this.router = router
	}

	static setCouponsStore(couponsStore: CouponsStore): void {
		this.couponsStore = couponsStore
	}

	static setSetNotification(setNotification: Function): void {
		this.setNotification = setNotification
	}

	static setCloseNotification(closeNotification: Function): void {
		this.closeNotification = closeNotification
	}

	static setAreAllStoresClosed(areAllStoresClosed: Function): void {
		this.areAllStoresClosed = areAllStoresClosed
	}

	static setGetTranslatedTextByKey(getTranslatedTextByKey: (path: string, fallback?: string) => string): void {
		this.getTranslatedTextByKey = getTranslatedTextByKey
	}

	static setCouponModal(coupon: Coupon | null): void {
		if (!this.couponsStore) {
			throw new Error('Coupons store is not set')
		}

		this.couponsStore?.setCouponModal(coupon)
	}

	static showError(message: string, coupon: Coupon | null): void {
		if (!CouponFlowRepository.setNotification) {
			return
		}

		CouponFlowRepository.setNotification?.({
			open: true,
			title: message,
			onClose: () => {
				if (this.closeNotification) {
					this.closeNotification()
				}
			},
			okAction: () => {
				if (this.closeNotification) {
					this.closeNotification()
				}
			},
		})
	}

	static showConfirm(message: string, okAction: () => void, cancelAction?: () => void): void {
		if (!CouponFlowRepository.setNotification) {
			return
		}

		CouponFlowRepository.setNotification?.({
			open: true,
			title: message,
			cancelAction,
			okAction,
			onClose: () => {
				if (cancelAction) {
					cancelAction()
				}
				if (this.closeNotification) {
					this.closeNotification()
				}
			},
		})
	}

	static getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<Coupon> {
		if (!this.couponsStore) {
			throw new CouponError('Coupons store is not set', ErrorCode.COUPON_NOT_FOUND)
		}
		return this.couponsStore.getCoupon(code, checkCouponOn)
	}

	static isLocalized(): boolean {
		return AddressManager.isUserLocalized()
	}

	static resetLocalization(): void {
		AddressManager.resetLocalization()
	}

	static getAndUpdateSession(): Promise<
		| {
				menuRes: any
				storeMetaData: any
				serverSession: any
				menuUrl: any
		  }
		| undefined
	> {
		return AddressManager.onMenuClickWhenLocalized()
	}

	// Returns true if the localization header can be opened on the page
	static isPageLegal(currentPath: string): boolean {
		const legalPagesRegex = [
			/^\/$/,
			/^\/home$/,
			/^\/[^\/]+\/deals$/,
			// /^\/[^\/]+\/menu$/, REMOVED BECAUSE OF A BLACK OVERLAY ON THIS PAGE ONLY - needs to be fixed
			/^\/[^\/]+\/menu\/.*$/,
			/^\/[^\/]+\/coupons$/,
		]

		for (const regex of legalPagesRegex) {
			if (regex.test(currentPath)) {
				return true
			}
		}

		return false
	}

	static openLocalizationModal(couponToApply: CouponToApply): void {
		Home.handleItemSelectedMode(couponToApply, User)
	}

	static async isStoreClosed(): Promise<boolean> {
		const localizedStoreId = AddressManager.getLocalizedStoreId()

		if (localizedStoreId) {
			const storeFrontUrl = Infra.appParams.storefrontUrl
			const { status, availability } = (await sendRequest(
				false,
				`${storeFrontUrl}/v1/stores/${localizedStoreId}?channel=WEB&%24pick=availability%2Cstatus`,
				'GET'
			)) as {
				status: StoreStatus
				availability: Availability
			}

			if (!availability) {
				return true
			}

			if (status !== 'Active') {
				return true
			}

			return !isAvailableNow(availability)
		}

		return false
	}

	static isChainClosed(): boolean {
		if (!CouponFlowRepository.areAllStoresClosed) {
			return true
		}

		return CouponFlowRepository.areAllStoresClosed()
	}

	static isCouponForChannel(couponChannels: Channel[]): boolean {
		return isCouponForChannel(couponChannels)
	}

	static isUserLoggedIn() {
		return Account?.isAuthenticated() ?? false
	}

	static openLoginModal() {}
}

/* "Timezone" is always a IANA timezone string e.g. "Europe/Berlin". List of timezones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */

// Returns the current device's (browsing client user) timezone
export function getCurrentTimezone() {
	// NOTE: as of April 2022, this works in about 94% of browsers. See: https://stackoverflow.com/a/34602679/522169.
	// If unable to get timezone - it returns UTC (Might be better to return CET in our European system)
	const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
	return timezone ?? 'UTC'
}

export function getCurrentDateStringByTimezone(timezone: unknown): string {
	try {
		if (typeof timezone !== 'string') {
			throw new Error(`Timezone must be a string , got ${timezone}`)
		}
		return new Date().toLocaleString('en-US', { timeZone: timezone })
	} catch (error) {
		console.error(`Error in getCurrentDateStringByTimezone: ${error}`)
		return new Date(new Date().toUTCString()).toLocaleString('en-US')
	}
}

export function getCurrentDateByTimezone(timezone: unknown): Date {
	try {
		if (typeof timezone !== 'string') {
			throw new Error(`Timezone must be a string, got ${timezone}`)
		}
	} catch (error) {
		console.error(`Error in getCurrentDateByTimezone: ${error}`)
		return new Date(new Date().toUTCString())
	}
	return new Date(new Date().toLocaleString('en-US', { timeZone: timezone }))
}

// Given a Date object, returns the weekday name as a string, either the short or the long version
export const getLocalWeekdayName = (date: Date, locale: string, short = true) =>
	date.toLocaleDateString(locale, { weekday: short ? 'short' : 'long' })

// Given a Date object and a locale, return the hour in a local format as HH:MM
export const getLocalHourString = (date: Date, locale: string, withSeconds = false) =>
	date.toLocaleTimeString(locale, { timeStyle: withSeconds ? 'medium' : 'short' })

// Given a date object and a locale, return the full name of the month
export const getLocalMonthName = (date: Date, locale: string, short = true) => date.toLocaleDateString(locale, { month: short ? 'short' : 'long' })

export const FULL_DAY_MILLISECONDS = 86400000

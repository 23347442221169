import styled from 'styled-components'
import IconComponent from 'themes/IconComponent'
import React from 'react'
import TypographyPro from 'themes/TypographyPro'

type Variant = 'info' | 'error' | 'warning'

interface Props {
	variant: Variant
	message: string
}

const Container = styled.div<{ variant: Variant }>`
	display: flex;
	width: 100%;
	border-radius: var(--borderRadiusBtn);
	padding: 0.5rem 0.75rem;
	gap: 0.5rem;
	align-items: center;

	background: ${({ variant }) => {
		switch (variant) {
			case 'warning':
				return 'var(--warningBg)'
			case 'error':
				return 'var(--errorBg)'
			case 'info':
			default:
				return 'var(--infoBg)'
		}
	}};
`

const Icon = styled(IconComponent).attrs<{ variant: Variant }>(({ variant }) => ({ width: '1.5rem', asset: `/icons/${variant}.svg` }))<{
	variant: Variant
}>`
	color: ${({ variant }) => {
		switch (variant) {
			case 'warning':
				return 'var(--err)'
			case 'error':
				return 'var(--warn)'
			case 'info':
			default:
				return '#106083'
		}
	}};
`

const Text = styled(TypographyPro).attrs({ variant: 'BodyRegular' })`
	color: var(--plainNotificationFont);
`

const Alert: React.FC<Props> = ({ variant, message }) => (
	<Container variant={variant}>
		<Icon variant={variant} />
		<Text>{message}</Text>
	</Container>
)

export default Alert

// @ts-nocheck
import {
	WEB_VIEW_LOADED,
	ASK_FOR_USER_LOCATION,
	ASK_PUSH_NOTIFICATIONS,
	OPEN_NATIVE_BROWSER,
	SET_CUID,
	ANALYTICS_EVENT,
	ASK_TO_RATE_APP,
	INIT_EXTERNAL_URLS,
	ENABLE_FIREBASE_ANALYTICS,
	UNRECOVERABLE_SERVER_ERROR,
} from '../constants/mobileEvents'
import type { NextRouter } from 'next/router'
import { applicationRequiresUpdate } from 'utils/mobileUtils'
import { CONSTANTS } from 'utils/constants'
import type { Stores } from 'contexts/StoresContext'

/**
 * Send and receive events from and to RN
 * See the doc: https://tictuk.atlassian.net/wiki/spaces/PROJECTS/pages/6389776/Communication+between+RN+and+Web+View+RJS
 */
export const ReactNativeComms = {
	/**
	 * Notify the React Native platform the home-page has loaded so it can hide the splash screen
	 * It also start listening all messages coming from React Native app.
	 */
	init: (
		stores: Stores,
		Infra,
		Cart,
		Account,
		Home,
		ItemAdditions,
		MobileApplication,
		User,
		AddressManager,
		Application,
		history: null | NextRouter = null,
		externalUrls = []
	) => {
		ReactNativeComms.sendMessage.webViewLoaded()
		ReactNativeComms.sendMessage.initExternalUrls(externalUrls)

		ReactNativeComms.sendMessage.firebaseAnalyticsEvents(Infra.appParams?.features?.enableFirebaseAnalytics || false)

		window.addEventListener(
			'message',
			MobileApplication.handleMobileApplicationEvent(stores, Infra, Cart, Account, Home, ItemAdditions, User, AddressManager, Application),
			false
		)
		if (history) {
			MobileApplication.setHistory(history)
		}

		try {
			// hotfix for force-mobile-app-update-popup
			const currentMobileVersion = localStorage.getItem(CONSTANTS.MOBILE_APP.VERSION_LOCAL_STORAGE_KEY) || ''
			const minimalRequiredVersion = Infra.getParams()?.mobileApp?.minimalRequiredVersion

			if (minimalRequiredVersion && applicationRequiresUpdate(currentMobileVersion, minimalRequiredVersion)) {
				MobileApplication.openMobileAppUpdateDialog(Infra)
			}
		} catch (err) {
			console.error(err)
		}
	},

	/**
	 * Single function to send an event to RN app.
	 * It should not be called directly, use ADHOC functions from this.sendMessages instead.
	 * It takes a JS object in the form {event, data}
	 */
	sendMessageToReactNative: (messageObject) => {
		if (window.ReactNativeWebView) {
			console.info(`**** Sending message ${messageObject.event} to RN`)
			try {
				window.ReactNativeWebView.postMessage(JSON.stringify(messageObject))
			} catch (error) {
				console.error(`Could not send the message ${messageObject} to RN`)
			}
		} else {
			console.warn(`**** Web app is not loaded in WebView`)
		}
	},
	/**
	 * This object contains adhoc functions to send events to RN.
	 * They can receive a dataObject object.
	 */
	sendMessage: {
		webViewLoaded: () => {
			ReactNativeComms.sendMessageToReactNative({
				event: WEB_VIEW_LOADED,
			})
		},
		askForLocation: () => {
			ReactNativeComms.sendMessageToReactNative({
				event: ASK_FOR_USER_LOCATION,
			})
		},
		askPushNotifications: () => {
			ReactNativeComms.sendMessageToReactNative({
				event: ASK_PUSH_NOTIFICATIONS,
			})
		},
		askToRateTheApp: () => {
			ReactNativeComms.sendMessageToReactNative({
				event: ASK_TO_RATE_APP,
			})
		},
		openNativeBrowser: (url) => {
			ReactNativeComms.sendMessageToReactNative({
				event: OPEN_NATIVE_BROWSER,
				data: {
					url,
				},
			})
		},
		setCUID: (CUID: string) => {
			ReactNativeComms.sendMessageToReactNative({
				event: SET_CUID,
				data: {
					CUID,
				},
			})
		},
		sendAnalyticsEvent: (analyticsType, analyticsEventName, ...eventData) => {
			// because enhanced ecom events have a varying number of args, we must always destructure the related method param
			// and it the analyticsType is a custom event, then there is only 1 method param
			const data = analyticsType === 'ENHANCED_ECOMMERCE_EVENT' ? [...eventData] : eventData[0]

			ReactNativeComms.sendMessageToReactNative({
				event: ANALYTICS_EVENT,
				analyticsType,
				analyticsEventName,
				data,
			})
		},
		initExternalUrls: (urls) => {
			ReactNativeComms.sendMessageToReactNative({
				event: INIT_EXTERNAL_URLS,
				data: {
					urls,
				},
			})
		},
		firebaseAnalyticsEvents: (isOn) => {
			ReactNativeComms.sendMessageToReactNative({
				event: ENABLE_FIREBASE_ANALYTICS,
				data: {
					isOn,
				},
			})
		},
		unrecoverableServerError: (error) => {
			ReactNativeComms.sendMessageToReactNative({
				event: UNRECOVERABLE_SERVER_ERROR,
				data: {
					error,
				},
			})
		},
	},
}

export default ReactNativeComms

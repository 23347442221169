import { StoreContext } from 'contexts/StoreContext'
import { useContext } from 'react'
import type { NextRouter } from 'next/router'

type StateObject = Record<string, string | string[]>
type NextRouterWithQuery<T extends StateObject = StateObject> = NextRouter & { query?: Partial<T> }

export default function useLocation<T extends StateObject = StateObject>(): NextRouterWithQuery<T> {
	const { router } = useContext(StoreContext).nextJS
	return router as NextRouterWithQuery<T>
}

export type { NextRouterWithQuery as Location }

import type { ParsedUrlQuery } from 'querystring'
import { CONSTANTS, ORDER_TYPES, ORDER_TYPES_QUERY_PARAMS } from 'utils/constants'
import { getCodeFromUrlHash, isMobileApp, isWebviewFlow } from 'utils/utils'
import { Channel, OrderType } from 'types/Coupons'
import { getDealRedirectQueryString, getQueryStringAndCouponCode } from 'utils/dealsUtils'
import type { NextRouter } from 'next/router'
import { THEME_STRATEGY } from 'constants/theme'
import { getThemeUrl } from 'utils/theme/themeManager'
import type _Infra from 'mobx/Infra'

export const extractCouponCodeFromOffer = (obj: any, router: NextRouter): string | null => {
	switch (obj?.target) {
		case 'coupon':
			return obj?.refName || null
		case 'item':
			return obj?.refId || null
		case 'lp': {
			const { queryParamsString, couponCode } = getQueryStringAndCouponCode(obj.urlQs)
			const dealRedirectQueryString = getDealRedirectQueryString(queryParamsString, couponCode)
			router.push(`/?${dealRedirectQueryString}`)
			break
		}
		default:
			return null
	}

	return null
}

export const readCouponDetailsFromUrl = ({ query, hash }: { query: ParsedUrlQuery; hash: string }) => {
	if (!hash.includes(CONSTANTS.URL_QUERY_HASH_KEY.DISCOUNT) && !hash.includes(CONSTANTS.URL_QUERY_HASH_KEY.ITEM)) {
		return null
	}

	const couponCode = decodeURI(getCodeFromUrlHash(hash))

	let orderTypeToForce = null

	if (query.ot && typeof query.ot === 'string') {
		switch (query.ot) {
			case ORDER_TYPES.DELIVERY:
			case CONSTANTS.DELIVERY_METHODS.DELIVERY.toString():
			case ORDER_TYPES_QUERY_PARAMS.DELIVERY_CLASS:
				// todo: try to link those order types
				orderTypeToForce = OrderType.DELIVERY
				break
			case ORDER_TYPES.PEAKUP:
			case ORDER_TYPES.PICKUP:
			case CONSTANTS.DELIVERY_METHODS.PICKUP.toString():
			case ORDER_TYPES_QUERY_PARAMS.PEAK_UP_CLASS:
				// todo: try to link those order types
				orderTypeToForce = OrderType.PICKUP
				break
			default:
				break
		}
	}

	return { code: couponCode, orderTypeToForce }
}

export const getDefaultImgUrl = (Infra: typeof _Infra) => {
	const themeStrategy = Infra.appParams.theme || THEME_STRATEGY.GENERIC
	const chainId = Infra?.appParams?.c
	const brand = Infra?.appParams?.brand

	return `${getThemeUrl(themeStrategy, chainId, brand)}/assets/images/placeholder.png`
}

export const getCurrentChannel = (): Channel => (isMobileApp() ? Channel.APP : Channel.WEB)

export const isCouponForChannel = (couponChannels: Channel[]): boolean => couponChannels.includes(getCurrentChannel())
